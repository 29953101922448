import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useUserContext } from 'common/UserProvider';
import useMetriCool from 'common/useMetriCool';

import { Trans } from 'react-i18next';
import { Link } from 'gatsby';
import { ELIXIR_URL, PASSWORDLESS_URL } from 'common/const';

import SEO from '@layout/SEO';
import Header from '@layout/Header';
import { Button } from '@global/Buttons';
import { H1, HP, P } from '@global/Texts';

import metaImgPath from 'images/meta-img-pre-register.png';

import './styles.scss';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const SignUp: FC = () => {
	const { t } = useTranslation();
	useMetriCool();

	const { user } = useUserContext();

	useEffect(() => {
		if (!user) navigate(PASSWORDLESS_URL);
	}, []);

	return (
		<>
			<SEO
				title={t('page.title')}
				description={t('comingSoon.pageDescription')}
				lang="en"
				imgPath={metaImgPath}
				imgAlt=""
				meta={[]}
			/>
			<main className="sign-up">
				<div className="xxl:container mx-auto text-white">
					<Header />
					<div className="text-center max-w-3xl mx-auto pt-32 xl:pt-20 px-4">
						<H1 className="mb-2">{t('signUpMain.title')}</H1>
						{user && (
							<>
								<HP className="mb-2">
									{t('signUpMain.alreadySignedUp', { email: user.userEmail })}
								</HP>
								<Button
									className="mb-4 mt-2"
									text={t('signUpMain.buttonLauncher')}
									variant="primary"
									onClick={() => navigate(ELIXIR_URL)}
								></Button>
							</>
						)}

						<div className="text-center max-w-xl mx-auto">
							<span>
								<Trans className="text-base italic">
									We do not own your private keys and cannot access your funds
									without your confirmation. See{' '}
									<Link
										className="hover:text-blue underline"
										to={process.env.GATSBY_TERMS_URL}
									>
										Terms of Use
									</Link>
								</Trans>
							</span>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default SignUp;
