import { useEffect } from "react";

const useMetriCool = () => {
  useEffect(() => {
    function loadScript(a) {
      var b = document.getElementsByTagName("head")[0],
        c = document.createElement("script");
      c.type = "text/javascript";
      c.src = "https://tracker.metricool.com/resources/be.js";
      c.onreadystatechange = a;
      c.onload = a;
      b.appendChild(c);
    }

    loadScript(function () {
      beTracker.t({ hash: "c95619c28bc124d0024466ce12ed79c8" });
    });
  }, []);
};

export default useMetriCool;
